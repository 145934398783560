export const personalwebsiteData = {
    title: "Personal Portfolio",
    description1: "My personal portfolio is a website that serves as a comprehensive repository of my information and accomplishments. It offers a visually appealing alternative to my traditional resume, allowing me to present myself in a more engaging manner. I created it using a template sourced from GitHub, which I enhanced by incorporating additional features. These enhancements include a dedicated projects page, functional LinkedIn and Github buttons, and the addition of extra navigation buttons to facilitate easy exploration of the webpage.",
    description2: "This was based on a template found on Github with additonal features that I added. Some of these were a decidated projects page, working LinkedIn, and Github buttons, as well as more buttons added to the menu for navigation of the webpage.",
    sensordiagrams: "The Added Features",
    description3: "Projects Page",
    description4: "The original template limited the display of projects to three per row, causing any additional projects to stack beneath the previous ones. While functional, this arrangement occupied more space than necessary. I aimed to streamline the main page by showcasing only three projects and redirecting any extras to a separate page. To achieve this, I introduced a 'View All' button that would only appear if more than three projects were available. Upon clicking it, users would be directed to a new page, offering the option to search projects by name or by the skills and languages used. Alternatively, users who didn't wish to search could simply view all projects displayed below the search bar. For convenience, a home button was included to return users to the main page. The primary challenge in this endeavor was in implementing the search bar. Because I am still a beginner in JavaScript and web development, I had to read documentation and research to ensure the proper functioning of this feature.",
    description5: "Redirecting Buttons",
    description6: "Another feature I had to incorporate were the redirecting buttons, like the LinkedIn, GitHub, and Resume buttons displayed at the beginning of the main page. Initially, these buttons lacked functionality and were non-clickable, merely displaying the icons without any interactive features. I implemented the necessary links to ensure they functioned properly while avoiding disruptions to the overall page layout. To maintain a seamless user experience, I configured these buttons to load the relevant information in new links, enabling users to retain the original page in their browser while accessing the desired resources.",
    description7: "Navigation Buttons",
    description8: "In the original template, the menu tab lacked certain buttons that I found essential. Notably, there were no buttons to directly navigate users to the skills and projects sections of the webpage. This meant that users had to scroll down to access these sections, which although functional, wasn't as user-friendly as I desired. Therefore, I aimed to enhance the user experience by introducing these navigation buttons. Integrating these additional buttons into the menu tab presented a similar challenge to the redirecting buttons mentioned earlier. The main concern was maintaining the original functionality of the menu tab while incorporating these new buttons, ensuring that the tab continued to work seamlessly as it originally had.",
    backlink: '/projects',
}