export const weatherappData = {
    title: "WeatherApp",
    description1: "This is one of the first projects that I created. It is a combination of both an HTML/CSS site as well as an Android Studo app. It uses the WeatherAPI API to display data about a city that was user-input.",
    description2: "As mentioned above, there were two parts for this. The first one was the HTML/CSS version. This is a simple site that will allow the user to enter a city and click a button. This will then display several attributes of the weather of that particular city. The android is somewhat similar in that it also allows the user to enter the city that they want the weather of. However, it doesn't display as many attributes as the HTML/CSS version.",
    thedifferences: "The Original",
    description3: "HTML/CSS Version",
    description4: "This version would allow the user to enter a city and then would display several attributes about the particular city when they press a button. These attributes include the location (state, country), the temperature (in Celsius and Fahrenheit), and word description of the weather (sunny, raining, etc.). Additionally, depending on this description, the background and text color will change. For example, if the weather at a particular city is cloudy, then it would change the background color to grey and the text to white.",
    description5: "Kotlin Version",
    description6: "This version was created using Kotlin and Android Studio, I created it when I was first gaining familiarity with Android Studio and Kotlin. Unlike the other version, this one doesn't display as much information and only displays the temperature in Celsius. However, for this one, the background color changes depending on the temperature of the city rather than the description.  ",
    backlink: '/projects',
    updates: 'Although the project was functional, it was not very visually appealing, so I decided to shake things up. Back when I first built it, I was more focused on the functionality than the appeal, so I figured, why not make it look as good as it works? Thus, I gave the site a makeover, starting with adding OpenWeatherAPI for more cool features and tossing in some JavaScript for extra flair. Plus, I tinkered around with various JavaScript libraries to jazz up user interaction, adding smooth animations and interactive elements to make browsing more fun. And to top it off, I added a neat feature where the background changed based on the weather conditions, using source.unsplash to find fitting images. It was a small touch that made the site more engaging and enjoyable for users. By continuously playing around and changing the design and functionality of the site, I aimed to create a seamless and enjoyable experience for all visitors.',
    web: 'As I mentioned earlier, my primary aim in revamping the site was to enhance its visual appeal. To achieve this, I implemented a search box where users can input the city they are interested in and a search button to send the request to the API. Once the user submits the request, the relevant information is displayed below the search box. This setup not only makes the site more interactive but also ensures a user-friendly experience, allowing visitors to easily access the weather information they are seeking. Here is an example of what it looks like:'
   
    
}