export const otherData = [
    'Git',
    'Ubuntu',
    'GNURadio',
    'Microcontrollers',
    'Signal Processing',
    'PCB Layout',
    'Circuit Design & Analysis',
    'AutoCAD',
    'OnShape',
    'Soldering',
    'Flask',
    'React',
    'Android',
    'WiFi',
    'ROS2'
]

