export const soccersentimentData = { 
    title: 'Reddit Soccer Sentiment Analysis',
    description1: "I developed a user-friendly Flask web application for soccer sentiment analysis, which seamlessly integrates Reddit and Soccer APIs with the ROBERTA model to generate insightful sentiment analysis plots based on user-entered team names and competition details. I implemented a modular structure within the Flask application, partitioning functionality into separate files to enhance scalability, maintainability, and code organization. Creating appealing HTML templates with various design details, including form validation mechanisms and descriptive placeholder text, was also part of my contributions.",
    description2: "For this project, I utilized external APIs such as Reddit and the Soccer API, along with ROBERTA, a pre-trained NLP model. The website operates by triggering a call to the Soccer API upon the user clicking the 'submit' button, retrieving the team's details including players and manager. Subsequently, it utilizes the Reddit API to fetch the post-match thread from r/soccer, gathers all comments, and applies filtering to remove punctuation and stopwords. These processed comments are then fed into ROBERTA for sentiment analysis, while keeping track of the team each comment refers to. This data is used to generate the plots displayed on the results page.",
    mainpage: 'Main Page',
    mainpagedescrp: 'The main page of the web application features a simple yet intuitive design crafted using HTML. It allows users to input the required information, accompanied by descriptive placeholder text guiding users on what data to input into each field. To add a touch of personal passion and align with the soccer theme, the background imagery showcases the iconic Santiago Bernabeu stadium. As a lifelong fan of Real Madrid, with aspirations to visit their historic stadium, this choice not only adds visual flair but also resonates deeply with my personal connection to the sport. Furthermore, each input field has a subtle soccer ball icon, reinforcing the soccer theme of the application',
    resultpage: 'Results Page',
    resultpagedesc: "The result page of the web application shows the generated graphs created from the data entered on the main page. These plots were created using Python's matplotlib library, to provide a visual representation of sentiment analysis for each team. By examining these plots, users can gain a comprehensive understanding of the overall sentiment associated with each team. There are cases where both teams exhibit a majority of either positive or negative sentiment, these plots serve as an indicator of the game's quality. For instance, during the 2022 World Cup Final showdown between Argentina and France, the corresponding plots demonstrated this, with both sides having an overall positive sentiment.",
    backlink: '/projects',
    argentinafrance: '2022 World Cup Final Plots',
    demo: 'On the main page, you have the option to select your preferred teams from the image provided below or manually input any other teams you wish to analyze. Additionally, you need to specify the competition in which these teams are participating, whether it be the Champions League, World Cup, La Liga, or another league. Please be advised that the generation of sentiment analysis plots may take some time as it occurs in real-time and is contingent upon the volume of comments processed by the model.',
    additionalInfo: {
        text: 'If you would like to try it for yourself, you can access the site here:',
        link: 'https://leogutierrez.pythonanywhere.com/'
    }

}