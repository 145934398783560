export const pokerandomData = {
    title: "PokeRandom",
    description1: "PokeRandom is an Android application designed to showcase a dynamic RecyclerView displaying information about 20 Pokémon. The app employs the PokeAPI, a RESTful API, to randomly retrieve data for these Pokémon, including their images, names, and weights. As users scroll through the list, PokeRandom loads additional Pokémon information, creating a smooth and interactive experience. The underlying mechanism relies on AsyncHTTPClient to efficiently make API calls and populate the RecyclerView with each Pokémon's image, name, and weight.",
    description2: "This Android application features a captivating design scheme centered around a vibrant color palette of red, peach, and orange tones. To enhance the visual aesthetics, the app incorporates three distinct fonts: Aladin, Architects_Daughter, and Atomic_Age. Additionally, the design extends to the textual elements, with two custom styles integrated into the themes.xml file. These styles are tailored to enhance the presentation of Pokémon names and their respective weights, ensuring a visually appealing and cohesive user experience. ",
    sensordiagrams: "The Process",
    description3: "Initial creation",
    description4: "At the start, my primary focus was solely on the ability to successfully make an API call and display the retrieved data. Consequently, the initial version of the project employed a straightforward approach, featuring a button that, when pressed, triggered the generation of a random Pokémon. At this stage, the project did not incorporate the more advanced elements such as the RecyclerView, custom fonts, or distinct styles.",
    description5: "The RecycleView",
    description6: "Once I had successfully retrieved data from the PokeAPI, my immediate objective was to enhance the user experience by implementing a RecyclerView. This improved data presentation, as it allowed multiple Pokémon to be displayed simultaneously without the need for a button press. Furthermore, it introduced a scrolling functionality, changing the app into a more intuitive and user-friendly platform.",
    description7: "The Presentation",
    description8: "After successfully implementing the RecyclerView, the final step in the project was dedicated to enhancing its visual appeal. I opted for a color theme of vibrant shades of red, orange, and peach. In addition to the color scheme, I carefully selected fonts to complement the theme. Ultimately, I settled on the use of Aladin, Architects_Daughter, and Atomic_Age fonts, which significantly contributed to the overall visual charm of the end product. This thoughtful combination of colors and fonts resulted in a final product that was not only functional but also visually appealing, allowing for the best user experience possible. This final product is what's shown at the very top of the page.",
    backlink: '/projects',
}